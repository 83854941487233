:root {
  --color-primary: #87037B;
  --color-redirect-box-background: #EFEFEF;
  --color-copyright-box-background: #FFFFFF;
  --color-result-box-background: beige;
  --color-page-box-background: #EFEFEF;
  --color-icon-default: #000000;
  --color-icon-accent: #87037B;
  --color-text-background-default: white;
  --color-text-foreground-default: black;
  --color-text-background-reverse: #87037B;
  --color-text-foreground-reverse: white;
  --color-text-background-accent: white;
  --color-text-foreground-accent: #87037B;
  --color-text-background-warning: white;
  --color-text-foreground-warning: red;
  --color-button-background-default: #87037B;
  --color-button-foreground-default: white;
  --color-button-background-hover: #5a025a;
  --color-button-foreground-hover: white;
  --color-button-background-disabled: #E9E9E9;
  --color-button-foreground-disabled: #333333;
  --color-button-background-question: #30062c;
  --color-button-foreground-question: white;
  --color-placeholder: #AAAAAA;
  --color-red: #EE0000;
  --color-gray: #999999;
  --font-family-default: "Iropke Batang";
  --font-family-accent: "MICEGothic Bold";
  --font-family-gothic: "Pretendard-Regular";
  --font-size-md: clamp(1.0rem, 1.0rem + 0.5vw, 1.3rem);
  --font-size-xxl: calc(var(--font-size-md) * 2.2);
  --font-size-xl: calc(var(--font-size-md) * 1.96);
  --font-size-lg: calc(var(--font-size-md) * 1.4);
  --font-size-sm: calc(var(--font-size-md) * 0.8);
  --font-size-xs: calc(var(--font-size-md) * 0.6);
  --font-size-xxs: calc(var(--font-size-md) * 0.5);
  --font-size-default-button: var(--font-size-md);
  --line-height-factor: 1.5;
  --text-line-height-xxl: calc(var(--font-size-xxl) * var(--line-height-factor));
  --text-line-height-xl: calc(var(--font-size-xl) * var(--line-height-factor));
  --text-line-height-lg: calc(var(--font-size-lg) * var(--line-height-factor));
  --text-line-height-md: calc(var(--font-size-md) * var(--line-height-factor));
  --text-line-height-sm: calc(var(--font-size-sm) * var(--line-height-factor));
  --text-line-height-xs: calc(var(--font-size-xs) * var(--line-height-factor));
  --text-line-height-xxs: calc(var(--font-size-xxs) * var(--line-height-factor));
  --layout-max-width: 1150px;
  --layout-footer-height: calc(var(--font-size-sm) * 3.0);
}

html, body {
  height: 100%;
}

* {
  font-family: var(--font-family-default);
}

body {
  font-size: 16px;
  margin: 0;
  padding: 0;
  word-break: keep-all;
  box-sizing: border-box;
  font-family: var(--font-family-default), sans-serif;
}

body {
  line-height: 1.5;
  -webkit-font-smoothing: antialiased;
}

img,
picture,
video,
canvas,
svg {
  display: block;
  max-width: 100%;
}

input,
button,
textarea,
select {
  font: inherit;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  overflow-wrap: break-word;
}

@font-face {
  font-family: "Iropke Batang";
  font-style: bold;
  font-weight: 500;
  src: url("../../assets/fonts/IropkeBatangM.otf") format("truetype");
}
@font-face {
  font-family: "MICEGothic Bold";
  src: url("https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_2206-01@1.0/MICEGothic Bold.woff2") format("woff2");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "Pretendard-Regular";
  src: url("https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Regular.woff") format("woff");
  font-weight: 400;
  font-style: normal;
}
/* @font-face {
    font-family: "Iropke Batang";
    font-style: bold;
    font-weight: 500;
    src: url("https://cdn.jsdelivr.net/font-iropke-batang/1.2/IropkeBatangM.eot");
    src: url("https://cdn.jsdelivr.net/font-iropke-batang/1.2/IropkeBatangM.eot?#iefix") format('embedded-opentype'),
         url("https://cdn.jsdelivr.net/font-iropke-batang/1.2/IropkeBatangM.woff") format('woff'),
         url("https://cdn.jsdelivr.net/font-iropke-batang/1.2/IropkeBatangM.ttf") format('truetype');
  } */
.text-size-xxl {
  font-size: var(--font-size-xxl);
}

.text-size-xl {
  font-size: var(--font-size-xl);
}

.text-size-lg {
  font-size: var(--font-size-lg);
}

.text-size-md {
  font-size: var(--font-size-md);
}

.text-size-sm {
  font-size: var(--font-size-sm);
}

.text-size-xs {
  font-size: var(--font-size-xs);
}

.text-size-xxs {
  font-size: var(--font-size-xxs);
}

.text-color-default {
  color: var(--color-text-foreground-default);
}

.text-color-reverse {
  color: var(--color-text-foreground-reverse);
}

.text-color-red {
  color: var(--color-red);
}

.text-color-gray {
  color: var(--color-gray);
}

.text-color-accent {
  color: var(--color-text-foreground-accent);
}

.text-color-warning {
  color: var(--color-text-foreground-warning);
}

.text-bold {
  font-weight: bold;
}

.text-font-accent {
  font-family: var(--font-family-accent);
}

.div-center {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 0 1 auto;
  gap: 0.5rem;
}

.div-left {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 0 1 auto;
  gap: 0.5rem;
  justify-content: flex-start;
}

button {
  text-transform: "none";
  font-size: var(--font-size-default-button);
  margin: 2rem 1rem 2rem 1rem;
  padding-top: 0.7rem;
  padding-bottom: 0.7rem;
  min-width: 15rem;
  border-width: 0rem;
  border-radius: 15rem;
  cursor: pointer;
}

.common-button {
  background-color: var(--color-button-background-default);
  color: var(--color-button-foreground-default);
}

.common-button-disabled {
  background-color: var(--color-button-background-disabled);
  color: var(--color-button-foreground-disabled);
}

.common-button:hover {
  background-color: var(--color-button-background-hover);
  color: var(--color-button-foreground-hover);
}

.login-button {
  background-color: var(--color-text-background-default);
  color: var(--color-text-foreground-default);
  min-width: 5rem;
}

.login-button:hover {
  background-color: var(--color-text-background-reverse);
  color: var(--color-text-foreground-reverse);
  min-width: 5rem;
}

.next-button, .next-button-disabled {
  background-color: var(--color-button-background-default);
  color: var(--color-button-foreground-default);
  padding: 1rem;
  margin-top: 1rem;
  margin-bottom: 1rem;
  width: 90%;
}

.next-button-disabled {
  background-color: var(--color-button-background-disabled);
  color: var(--color-button-foreground-disabled);
}

.next-button:hover {
  background-color: var(--color-button-background-hover);
  color: var(--color-button-foreground-hover);
}

.next-question-button, .next-question-button-disabled {
  background-color: var(--color-button-background-question);
  color: var(--color-button-foreground-question);
  min-width: 1rem;
  border-radius: 10;
  width: 100px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid #FFFFFF;
}

.next-question-button-disabled {
  background-color: var(--color-button-background-disabled);
  color: var(--color-button-foreground-disabled);
}

.div-button-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: strach;
  width: 80%;
  margin-bottom: 1rem;
  margin-left: 2rem;
  margin-right: 2rem;
}

.div-button, .div-button-selected {
  padding-top: 2rem;
  padding-bottom: 2rem;
  margin-bottom: 2rem;
  border: 1px solid;
  border-color: var(--color-primary);
  border-radius: 50px;
  text-align: center;
  background-color: var(--color-text-background-default);
  color: var(--color-text-foreground-default);
}

.div-button:last-child {
  margin-bottom: 0rem;
}

.div-button:hover {
  background-color: var(--color-text-background-reverse);
  color: var(--color-text-foreground-reverse);
}

.div-button-selected {
  background-color: var(--color-text-background-reverse);
  color: var(--color-text-foreground-reverse);
}

.div-button-selected:last-child {
  margin-bottom: 0rem;
}

.area-divider {
  width: 100%;
  height: 0.1rem;
  background-color: var(--color-primary);
}

.area-divider-blank {
  width: 100%;
  height: 2rem;
}

.warning-message {
  background-color: var(--color-text-background-warning);
  color: var(--color-text-foreground-warning);
  font-size: var(--font-size-md);
}

.redirect-box {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 0 1 auto;
  gap: 0.5rem;
  box-shadow: 2px 2px 10px 2px rgba(0, 0, 0, 0.3);
  border: 1px solid #CCCCCC;
  background-color: var(--color-redirect-box-background);
  flex-direction: column;
  border: 2px solid;
  border-color: var(--color-primary);
  border-radius: 1rem;
  margin-top: 3rem;
  margin-bottom: 3rem;
  margin-left: auto;
  margin-right: auto;
  padding: 1rem;
  width: 90%;
}

.redirect-title {
  background-color: var(--color-text-background-default);
  color: var(--color-text-foreground-accent);
  background-color: var(--color-redirect-box-background);
  font-family: var(--font-family-accent);
  font-size: var(--font-size-lg);
}

.redirect-message {
  background-color: var(--color-text-background-warning);
  color: var(--color-text-foreground-warning);
  background-color: var(--color-redirect-box-background);
  font-size: var(--font-size-md);
  text-align: center;
  margin-top: 0.1rem;
  margin-bottom: 0.1rem;
}

.copyright-box {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 0 1 auto;
  gap: 0.5rem;
  background-color: var(--color-text-background-default);
  color: var(--color-text-foreground-default);
  background-color: var(--color-copyright-box-background);
  flex-direction: column;
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.copyright-link {
  color: var(--color-text-foreground-accent);
  font-size: var(--font-size-xs);
  text-decoration: none;
}

.copyright-text {
  color: var(--color-text-foreground-default);
  font-size: var(--font-size-xs);
  text-decoration: none;
}

.redirect-link {
  background-color: var(--color-text-background-default);
  color: var(--color-text-foreground-accent);
  font-size: var(--font-size-md);
  text-decoration: none;
}

.text-link {
  background-color: var(--color-text-background-default);
  color: var(--color-text-foreground-accent);
  font-size: var(--font-size-md);
  text-decoration: none;
  margin-top: 1rem;
}

.login-box {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 0 1 auto;
  gap: 0.5rem;
  box-shadow: 2px 2px 10px 2px rgba(0, 0, 0, 0.3);
  border: 1px solid #CCCCCC;
  background-color: var(--color-login-box-background);
  flex-direction: column;
  border: 2px solid;
  border-color: var(--color-primary);
  border-radius: 1rem;
  margin-top: 3rem;
  margin-bottom: 3rem;
  margin-left: auto;
  margin-right: auto;
  padding: 1rem;
  width: 40%;
  min-width: 15rem;
}

.form-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 1rem;
}

.form-label-wrapper {
  display: flex;
  align-items: center;
  flex: 0 1 auto;
  padding-left: 0.5rem;
  padding-top: 1rem;
  margin-bottom: 0.5rem;
}

.form-desc-wrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.form-component-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
}

.form-label {
  font-size: var(--font-size-md);
  font-weight: bold;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}

.form-desc {
  font-size: var(--font-size-sm);
  margin-right: 0.5rem;
  padding-left: 0.5rem;
}

.form-required {
  background-color: var(--color-text-background-warning);
  color: var(--color-text-foreground-warning);
  font-size: var(--font-size-sm);
}

.form-placeholder {
  color: var(--color-placeholder);
}

.question_wrapper {
  padding-bottom: 3rem;
}

.question {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 0 1 auto;
  gap: 0.5rem;
  padding: 2rem 1.2rem 3rem 1.2rem;
  margin-top: 0.5rem;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  font-size: var(--font-size-lg);
}

.question-address {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 0 1 auto;
  gap: 0.5rem;
  padding: 0rem;
  margin-top: 0.5rem;
  margin-left: auto;
  margin-right: auto;
  background-color: var(--color-text-background-default);
  color: var(--color-text-foreground-accent);
  font-family: var(--font-family-accent);
  font-size: var(--font-size-md);
}

.options {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 0 1 auto;
  gap: 0.5rem;
  flex-direction: column;
  align-items: stretch;
}

.option {
  background-color: var(--color-text-background-default);
  color: var(--color-text-foreground-default);
  margin: 0rem 0.5rem 0rem 0.5rem;
  padding: 0.8rem 1rem 0.8rem 1rem;
  min-height: 4rem;
  border-radius: 0.5rem;
  font-size: var(--font-size-md);
  display: flex;
  align-items: center;
  border: 1px solid var(--color-primary);
}

.option:hover {
  background-color: var(--color-text-background-reverse);
  color: var(--color-text-foreground-reverse);
  cursor: pointer;
}

.option-selected {
  background-color: var(--color-text-background-reverse);
  color: var(--color-text-foreground-reverse);
  box-shadow: 2px 2px 10px 2px rgba(0, 0, 0, 0.3);
  border: 1px solid;
  border-color: var(--color-primary);
  margin: 0rem 0.5rem 0rem 0.5rem;
  padding: 0.8rem 1rem 0.8rem 1rem;
  min-height: 4rem;
  border-radius: 0.5rem;
  font-size: var(--font-size-md);
  display: flex;
  align-items: center;
}

.option, .option-selected {
  font-size: var(--font-size-md);
}

.option-accent {
  font-family: var(--font-family-accent);
  font-weight: bold;
}

.layout-container {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin: 0rem;
  padding: 0rem;
}

.layout-header-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  border-bottom: 1px solid var(--color-primary);
}

.layout-header-fixed-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  background-color: var(--color-text-background-default);
  border-bottom: 1px solid var(--color-primary);
}

.layout-header-fixed-wrapper-shaded {
  display: flex;
  flex-direction: row;
  justify-content: center;
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  background-color: var(--color-text-background-default);
  border-bottom: 1px solid var(--color-primary);
  box-shadow: 2px 2px 10px 2px rgba(0, 0, 0, 0.3);
  border: 1px solid #CCCCCC;
  z-index: 100;
}

.layout-header {
  width: 100%;
  max-width: var(--layout-max-width);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-top: 0.8rem;
  padding-bottom: 0.8rem;
}

.layout-content-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 1rem;
  padding-left: 1rem;
  padding-right: 1rem;
  margin-top: 5rem;
  padding-top: 0.5rem;
}

.layout-content {
  width: 100%;
  max-width: var(--layout-max-width);
  display: grid;
  grid-template-columns: 1fr;
  gap: 0.5rem;
  padding-bottom: 5rem;
}

.layout-footer-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  border-top: 1px solid var(--color-primary);
  background-color: var(--color-copyright-box-background);
}

.layout-footer {
  width: 100%;
  max-width: var(--layout-max-width);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: var(--layout-footer-height);
}

.layout-header-title {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
}

.layout-header-title-text {
  display: grid;
  grid-template-columns: auto auto;
  -moz-column-gap: 0.5rem;
       column-gap: 0.5rem;
}

.layout-header-title-text-first {
  text-align: right;
  font-family: var(--font-family-accent);
  font-size: var(--font-size-md);
}

.layout-header-title-text-second {
  text-align: left;
  font-family: var(--font-family-accent);
  font-size: var(--font-size-md);
}

@media (max-width: 768px) {
  .layout-header-title-text {
    display: grid;
    grid-template-columns: 1fr;
  }
  .layout-header-title-text-first {
    text-align: center;
  }
  .layout-header-title-text-second {
    text-align: center;
    font-size: var(--font-size-sm);
  }
}
.layout-header-icon {
  display: flex;
  align-items: center;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  cursor: pointer;
}

.layout-header-icon:first-child {
  margin-left: 1rem;
}

.layout-header-icon:last-child {
  margin-left: 0rem;
  margin-right: 1rem;
}

.layout-fixed-footer-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  border-top: 1px solid var(--color-primary);
  background-color: var(--color-copyright-box-background);
  position: fixed;
  bottom: 0px;
  left: 0px;
  right: 0px;
}

.layout-fixed-header-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  border-bottom: 1px solid var(--color-primary);
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
}

.layout-fixed-question-button-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  position: fixed;
  bottom: calc(var(--layout-footer-height) + 1rem);
  left: 0px;
  right: 0px;
}

.top-scroll-button-wrapper {
  position: fixed;
  bottom: 4rem;
  right: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.top-scroll-button {
  min-width: 2.5rem;
  min-height: 2.5rem;
  padding: 0;
  margin: 0;
  background-color: var(--color-text-foreground-warning);
  color: var(--color-button-foreground-default);
  font-size: var(--font-size-sm);
}

.page_headline {
  font-size: var(--font-size-xl);
  color: var(--color-text-foreground-accent);
  line-height: var(--text-line-height-xl);
  margin-top: 0rem;
  margin-bottom: 5rem;
  padding-top: 0rem;
  padding-bottom: 0rem;
}

.page_title {
  font-size: var(--font-size-lg);
  color: var(--color-text-foreground-accent);
  line-height: var(--text-line-height-lg);
  margin-top: 2rem;
  margin-bottom: 2rem;
  padding-top: 0rem;
  padding-bottom: 0rem;
}

.page_title_accent {
  font-size: var(--font-size-lg);
}

.page_text {
  font-size: var(--font-size-md);
  line-height: var(--text-line-height-md);
  color: var(--color-text-foreground-default);
  margin-top: 0rem;
  margin-bottom: 2rem;
}

.align_justify {
  word-break: break-word;
  text-align: justify;
}

.page_subtext {
  font-size: var(--font-size-sm);
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.divider {
  margin-left: 0.7rem;
  margin-right: 0.7rem;
}

.accent_text {
  font-family: var(--font-family-accent);
  color: var(--color-text-foreground-accent);
  font-weight: bold;
}

.underline {
  text-decoration: underline;
}

.color_foreground_default {
  color: var(--color-text-foreground-default);
}

.icon {
  font-size: var(--font-size-md);
  margin-right: 0.7rem;
  color: var(--color-primary);
}

.page_dot {
  font-size: 0.1rem;
  margin-left: 1rem;
  margin-right: 0.5rem;
}

.page_center {
  text-align: center;
}

.page_ul_box {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.page_ul_item {
  padding-left: 2rem;
  padding-right: 0rem;
  text-indent: -1.5rem;
  word-break: normal;
  font-weight: normal;
}

.page_container {
  max-width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding-top: 2.5rem;
}

.page_container_old {
  max-width: 1150px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding-top: 2.5rem;
  padding-left: 1rem;
  padding-right: 1rem;
  margin-left: auto;
  margin-right: auto;
}

.page_intro_wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.page_check_wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.page_check_content_wrapper {
  display: grid;
  grid-template-columns: calc(var(--font-size-md) * 4.5) 5fr;
  row-gap: 0.75rem;
  background-color: var(--color-page-box-background);
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  padding-right: 1rem;
  border-radius: 0.5rem;
  padding: 1rem;
  font-size: calc(var(--font-size-md) * 0.9);
}

.page_check_content_subject {
  font-weight: bold;
}

.page_check_content_desc {
  font-weight: normal;
}

.page_guide_wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.page_guide_content {
  background-color: var(--color-page-box-background);
  border: 2px solid var(--color-page-box-background);
  border-radius: 0.5rem;
  padding-left: -1.5rem;
  padding-right: 1rem;
  font-size: calc(var(--font-size-md) * 0.8);
  line-height: calc(var(--text-line-height-md) * 0.8);
  margin-left: auto;
  margin-right: auto;
}

.page_guide_content_list {
  margin-top: 0.4rem;
  margin-bottom: 0.4rem;
}

.page_section_comment {
  font-size: calc(var(--font-size-md) * 0.6);
  text-align: center;
  margin-top: 0.5rem;
  font-weight: bold;
}

.page_section_title {
  font-size: calc(var(--font-size-md) * 1.1);
  font-family: var(--font-family-accent);
  margin-bottom: 0.8rem;
}

.color_black {
  color: var(--color-text-foreground-default);
}

.result_container {
  max-width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding-top: 2.5rem;
}

.result_title {
  font-size: var(--font-size-xl);
  font-weight: bold;
  margin-bottom: 0.5rem;
}

.result_subtitle {
  font-size: var(--font-size-lg);
  margin-top: 2rem;
  margin-bottom: 0.5rem;
}

.result_message {
  font-size: var(--font-size-md);
  margin-bottom: 0.5rem;
}

.result_paragraph {
  font-size: var(--font-size-md);
  font-weight: bold;
  margin-top: 2rem;
  margin-bottom: 0.5rem;
  font-family: var(--font-family-accent);
  padding-top: 2rem;
}

.result_subparagraph {
  font-size: calc(var(--font-size-md) * 0.8);
  margin-bottom: 0.1rem;
  font-weight: bold;
}

.result_text {
  font-size: calc(var(--font-size-md) * 0.9);
  margin-bottom: 0.5rem;
  line-height: calc(var(--text-line-height-md) * 0.9);
}

.result_subtext {
  font-size: calc(var(--font-size-md) * 0.8);
  margin-bottom: 1.5rem;
  line-height: calc(var(--text-line-height-md) * 0.8);
}

.result_accent_text {
  color: var(--color-text-foreground-accent);
  font-family: var(--font-family-accent);
  font-weight: bold;
}

.result_center {
  text-align: center;
}

.result_bg {
  background-color: var(--color-result-box-background);
  padding: 1rem 0.5rem 1rem 0.5rem;
  text-align: center;
}

.result_cover_image {
  box-shadow: 2px 2px 10px 2px rgba(0, 0, 0, 0.3);
  border: 1px solid #CCCCCC;
  width: 100%;
  border-radius: 0.5rem;
  margin-bottom: 1rem;
}

.icon {
  font-size: var(--font-size-md);
  color: var(--color-primary);
  margin-right: 0.7rem;
}

.icon_color_default {
  color: var(--color-icon-default);
}

.result_divider {
  width: 100%;
  height: 0.05rem;
  font-size: 0rem;
  background-color: var(--color-primary);
  margin-bottom: 0.5rem;
}

.result_dot {
  vertical-align: middle;
  font-size: 0.5rem;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}

.result_text_smaller {
  font-size: var(--font-size-sx);
  margin-bottom: 0.5rem;
}

.result_blank {
  margin-bottom: 3rem;
}

.result_bold {
  font-weight: bold;
}

@media (max-width: 768px) {
  .result_blank {
    margin-bottom: 1.5rem;
  }
}
.program_container {
  max-width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  gap: 0.5rem;
  background-color: var(--color-text-background-default);
  color: var(--color-text-foreground-default);
}

@media (max-width: 768px) {
  .program_container {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
  }
}
.program_items {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin-bottom: 1rem;
  padding-bottom: 1rem;
  border-bottom: 2px dotted var(--color-primary);
}

.program_categorys {
  display: flex;
  margin-bottom: 0rem;
}

.program_category {
  font-size: calc(var(--font-size-md) * 0.85);
  font-family: var(--font-family-accent);
  margin-bottom: 0rem;
}

.program_name {
  font-size: calc(var(--font-size-sm) * 1.2);
  font-weight: bold;
}

.program_desc1 {
  font-size: var(--font-size-sm);
}

.program_desc2 {
  font-size: var(--font-size-sx);
}

.program_cover_image {
  box-shadow: 2px 2px 10px 2px rgba(0, 0, 0, 0.3);
  border: 1px solid #CCCCCC;
  width: 100%;
  border-radius: 0.5rem;
  margin-bottom: 0.5rem;
}

.chart-box-default {
  font-size: 1rem;
  padding: 0.5rem;
  width: 100%;
  border: 1px solid #aaaaaa;
  background-color: #f9f9f9;
  border-radius: 0.5rem;
  box-shadow: 1px 1px 5px 1px rgba(0, 0, 0, 0.3);
}

:root {
  --dl-header-height: 2rem;
  --dl-padding:0.5rem;
  --grid-template-rows: calc(var(--dl-header-height) + var(--dl-padding) * 2) 1fr 3rem;
  --grid-template-rows-mobile: calc(var(--dl-header-height) + 1rem + var(--dl-padding) * 2) 1fr 3rem;
}

.d-container {
  display: grid;
  grid-template-columns: 20rem 1fr;
  grid-template-rows: var(--grid-template-rows);
  margin: 0;
  height: 100%;
}

.dl-header {
  padding: var(--dl-padding);
  grid-column: 1/3;
  display: flex;
  flex-direction: row;
  background-color: #417690;
  justify-content: space-between;
  align-items: center;
  padding-left: 1rem;
  padding-right: 1rem;
  position: sticky;
  top: 0;
}

.dl-footer {
  padding: var(--dl-padding);
  grid-column: 1/3;
  background-color: #eeeeee;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
}

.dl-main-sidebar, .dl-module-sidebar {
  padding: var(--dl-padding);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  overflow-x: auto;
  overflow-y: auto;
}

.dl-main-main, .dl-module-main {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto auto auto 1fr;
  padding-top: 0.5rem;
  padding-left: 1rem;
  padding-right: 1rem;
  overflow-x: auto;
  overflow-y: auto;
}

.dl-toggle {
  display: flex;
  align-items: center;
}

.btn-toggle {
  cursor: pointer;
}

.d-a {
  text-decoration: none;
}
.d-a:hover {
  font-weight: bold;
}

.d-content {
  font-size: calc(var(--font-size-md) * 0.7);
}

.align-center {
  text-align: center;
}

.d-m-wrapper {
  margin-bottom: 2rem;
}

.d-m-name {
  padding: var(--dl-padding);
  background-color: #79AEC8;
  color: #ffffff;
  font-weight: bold;
  font-size: calc(var(--font-size-md) * 0.8);
}

.d-f-name {
  padding: var(--dl-padding);
  background-color: #FFFFFF;
  color: #003366;
  font-weight: bold;
  font-size: calc(var(--font-size-md) * 0.7);
  border-bottom: 1px solid #79AEC8;
}
.d-f-name:hover {
  background-color: #eeeeee;
  cursor: pointer;
}

.d-nav-top {
  background-color: #CCCCCC;
  font-size: var(--font-size-md);
  margin-top: 0rem;
  margin-bottom: 0.5rem;
  padding-left: 0.5rem;
}

.d-nav-m {
  font-family: var(--font-family-accent);
  font-size: calc(var(--font-size-md) * 1.2);
  font-weight: bold;
}

.d-nav-f {
  font-family: var(--font-family-accent);
  font-size: calc(var(--font-size-md) * 0.9);
  font-weight: bold;
}

.d-main-title {
  font-family: var(--font-family-accent);
  background-color: #EEEEEE;
  font-size: var(--font-size-md);
  font-weight: bold;
  margin-top: 0rem;
  margin-bottom: 0.5rem;
  padding-left: 0.5rem;
}

.d-sec-data-search {
  font-size: var(--font-size-sm);
  margin-top: 0rem;
  margin-bottom: 0rem;
  display: grid;
  grid-template-columns: repeat(5, 10rem) 10rem 1fr;
  grid-template-rows: auto;
  padding-left: 0.5rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.select-data-search {
  width: 9rem;
  border: 1px solid var(--color-primary);
  border-radius: 0.5rem;
}

.date-data-search {
  width: 9rem;
  border: 1px solid var(--color-primary);
  border-radius: 0.5rem;
}

.input-data-search {
  width: 10rem;
  border: 1px solid var(--color-primary);
  border-radius: 0.5rem;
}

.display-hidden {
  display: none;
}

.btn-data-search {
  margin: 0;
  padding: 0;
  min-width: 5rem;
  width: 1rem;
  border: 1px solid var(--color-primary);
  border-radius: 0.5rem;
  font-size: var(--font-size-sm);
  text-transform: uppercase;
}

.d-data-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.d-data-table {
  border-collapse: collapse;
  border-top: 2px solid #333333;
}

.d-data-header {
  background-color: #AAAAAA;
  border-bottom: 2px solid #333333;
}

.d-data-row {
  border-bottom: 1px solid #333333;
}
.d-data-row:nth-child(even) {
  background-color: #EEEEEE;
}
.d-data-row:hover {
  cursor: pointer;
  background-color: #AAAAAA;
}

.d-data-th {
  font-family: var(--font-family-accent);
  font-weight: bold;
  padding-top: 0.3rem;
  padding-bottom: 0.3rem;
  padding-left: 1rem;
  padding-right: 1rem;
  min-width: 5rem;
  text-align: left;
  padding-top: 0.6rem;
  padding-bottom: 0.6rem;
}

.d-data-td {
  text-align: left;
  font-weight: normal;
  padding-top: 0.3rem;
  padding-bottom: 0.3rem;
  padding-left: 1rem;
  padding-right: 1rem;
  min-width: 5rem;
  text-align: left;
}

@media (max-width: 768px) {
  .d-container {
    grid-template-columns: 1fr;
    grid-template-rows: var(--grid-template-rows-mobile);
  }
  .dl-header {
    flex-direction: column;
    justify-content: flex-start;
  }
  .dl-main-main {
    display: none;
  }
  .dl-module-sidebar {
    display: none;
  }
  .d-data-mobile-hidden {
    display: none;
  }
  .d-data-th {
    padding-top: 0.2rem;
    padding-bottom: 0.2rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    min-width: 3rem;
    text-align: left;
  }
  .d-data-td {
    padding-top: 0.2rem;
    padding-bottom: 0.2rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    min-width: 3rem;
    text-align: left;
  }
  .d-sec-data-search {
    padding-left: 0rem;
    grid-template-columns: repeat(4, auto);
    grid-template-rows: repeat(2, auto);
    row-gap: 0.3rem;
    -moz-column-gap: 0.2rem;
         column-gap: 0.2rem;
  }
  .select-data-search, .date-data-search, .input-data-search, .btn-data-search {
    width: 100%;
  }
  .input-data-search {
    grid-row: 2/3;
    grid-column: 1/4;
  }
  .btn-data-search {
    grid-row: 2/3;
    grid-column: 4/5;
  }
}
.tool-data-header {
  font-size: 1rem;
  text-align: center;
  border: 1px solid #000000;
  padding-top: 0.2rem;
  padding-bottom: 0.2rem;
}

.header-drivers {
  background-color: #333333;
  color: #FFFFFF;
}

.header-divisions {
  background-color: #999999;
  color: #333333;
}

.header-factors {
  background-color: #074949;
  color: #EEEEEE;
}

.header-questions {
  background-color: #484907;
  color: #EEEEEE;
}

.header-options {
  background-color: #296494;
  color: #EEEEEE;
}

.tool-data-text-wrapper {
  display: grid;
  grid-template-columns: repeat(3, 7% 23%) auto;
  font-size: calc(var(--font-size-md) * 1);
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.tool-data-text {
  border: 1px solid #cccccc;
  padding: 0.5rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.tool-data-form-wrapper {
  display: grid;
  grid-template-columns: max(15rem, 10%) auto;
  font-size: calc(var(--font-size-md) * 1);
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.tool-data-number {
  border: 1px solid #cccccc;
  padding: 0.5rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.selected-option {
  background-color: #333333;
  color: #ffffff;
}

.borderless {
  border: 0px;
}

.tool-data-drivers {
  display: grid;
  grid-template-columns: 3rem max(10rem, 15%) auto;
  font-size: calc(var(--font-size-md) * 1.2);
}

.tool-data-divisions {
  display: grid;
  grid-template-columns: 3rem max(10rem, 15%) auto;
  font-size: calc(var(--font-size-md) * 1.2);
}

.tool-data-factors {
  display: grid;
  grid-template-columns: 3rem max(13rem, 25%) auto;
  font-size: calc(var(--font-size-md) * 1);
}

.tool-data-questions {
  display: grid;
  grid-template-columns: max(15rem, 35%) auto;
  font-size: calc(var(--font-size-md) * 1);
}

.tool-data-options {
  display: grid;
  grid-template-columns: max(20rem, 100%);
  font-size: calc(var(--font-size-md) * 0.9);
  flex-grow: 1;
}

.tool-data-drivers-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.tool-data-divisions-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.tool-data-factors-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.tool-data-questions-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.tool-data-options-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

@media (max-width: 768px) {
  .tool-data-drivers {
    display: grid;
    grid-template-columns: auto;
  }
  .tool-data-divisions {
    display: grid;
    grid-template-columns: auto;
  }
  .tool-data-factors {
    display: grid;
    grid-template-columns: auto;
  }
  .tool-data-questions {
    display: grid;
    grid-template-columns: auto;
    font-size: calc(var(--font-size-md) * 0.9);
    font-weight: bold;
    background-color: #EEEEEE;
  }
  .tool-data-options {
    display: grid;
    font-size: calc(var(--font-size-md) * 0.9);
    font-weight: normal;
    background-color: #FFFFFF;
  }
  .tool-data-mobile-hidden {
    display: none;
  }
  .tool-data-text-wrapper {
    display: grid;
    grid-template-columns: 20% 80%;
    font-size: calc(var(--font-size-md) * 1);
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    row-gap: 0.5rem;
  }
  .grid-analysys-result {
    grid-column: 1/3;
  }
}
.pagination-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;
  margin-bottom: 1rem;
  gap: 1rem;
}

.pagination {
  border-radius: 5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  font-size: var(--font-size-sm);
  width: 2rem;
  height: 2rem;
}

.pagination-active {
  background-color: var(--color-button-background-default);
  color: var(--color-button-foreground-default);
  cursor: pointer;
}
.pagination-active:hover {
  background-color: var(--color-button-background-hover);
  color: var(--color-button-foreground-hover);
}

.pagination-disable {
  border: 1px solid var(--color-button-background-default);
  background-color: var(--color-button-background-disable);
  color: var(--color-button-forecround-disable);
  cursor: default;
}

.pagination-arrow {
  width: 3rem;
}

.pagination-omit {
  font-size: 0.2rem;
}

.tool-page-wrapper {
  display: grid;
  grid-template-columns: repeat(2, clamp(25rem, 1vw, 35rem));
  grid-template-rows: repeat(2, clamp(25rem, 1vw, 35rem));
  row-gap: 2rem;
  -moz-column-gap: 2rem;
       column-gap: 2rem;
}

.tool-page-tool-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid #ffffff;
  border-radius: 1rem;
  padding: 1rem;
  box-shadow: 2px 2px 10px 2px rgba(0, 0, 0, 0.3);
  border: 1px solid #CCCCCC;
}

.alone {
  width: 80%;
}

.lineheight_wide {
  line-height: var(--text-line-height-lg);
}

.left-align {
  text-align: left;
}

.premium-wrapper {
  border: 4px solid var(--color-primary);
  border-radius: 1rem;
}

.tool-page-tool-abbr {
  font-size: calc(var(--font-size-sm) * 1.2);
  font-weight: bold;
}

.tool-page-tool-name {
  font-size: calc(var(--font-size-sm) * 1.6);
  font-family: var(--font-family-accent);
  margin-top: 0.1rem;
  margin-bottom: 0.1rem;
}

.tool-page-tool-en {
  font-size: calc(var(--font-size-sm) * 0.9);
  color: var(--color-gray);
}

.tool-page-tool-phrase {
  font-size: calc(var(--font-size-md) * 1.1);
  margin-top: 2rem;
  margin-bottom: 1rem;
  text-align: center;
}

.tool-page-tool-desc {
  font-size: calc(var(--font-size-md) * 0.8);
  margin-top: 1rem;
  margin-bottom: 1rem;
  text-align: center;
  line-height: var(--text-line-height-sm);
}

.tool-page-button-wrapper {
  display: flex;
  margin-top: 1rem;
  margin-bottom: 1rem;
  gap: 1rem;
}

.tool-page-button-tool {
  background-color: var(--color-button-background-default);
  color: var(--color-button-foreground-default);
  font-size: var(--font-size-sm);
  font-family: var(--font-family-accent);
  margin: 0;
  padding: 0;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  min-width: 9rem;
  cursor: pointer;
}
.tool-page-button-tool:hover {
  background-color: var(--color-button-background-hover);
  color: var(--color-button-foreground-hover);
}

.alone-button {
  min-width: 12rem;
  font-size: var(--font-size-md);
}

.tool-page-button-explain {
  background-color: var(--color-button-background-default);
  color: var(--color-button-foreground-default);
  font-size: var(--font-size-sm);
  font-family: var(--font-family-accent);
  margin: 0;
  padding: 0;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  min-width: 9rem;
  cursor: pointer;
  background-color: var(--color-button-foreground-default);
  color: var(--color-button-background-default);
  border: 1px solid var(--color-button-background-default);
}
.tool-page-button-explain:hover {
  background-color: #eeeeee;
  color: var(--color-button-foreground-disable);
}

@media (max-width: 768px) {
  .tool-page-wrapper {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    width: 90%;
  }
  .tool-page-tool-abbr {
    font-size: calc(var(--font-size-md) * 1.2);
  }
  .tool-page-tool-name {
    font-size: calc(var(--font-size-md) * 1.6);
  }
  .tool-page-tool-en {
    font-size: calc(var(--font-size-md) * 0.9);
  }
  .tool-page-tool-phrase {
    font-size: calc(var(--font-size-sm) * 1.1);
  }
  .tool-page-tool-desc {
    font-size: calc(var(--font-size-md) * 0.8);
    line-height: var(--text-line-height-md);
  }
  .alone {
    width: 80%;
  }
  .lineheight_wide {
    line-height: var(--text-line-height-sm);
  }
}
.tool-page2-wrapper {
  display: grid;
  padding: 1rem;
  row-gap: 0.75rem;
}

.tool-page2-tool-wrapper {
  justify-content: center;
  align-items: center;
  border: 1px solid #cccccc;
  border-radius: 0.5rem;
  padding: 1rem;
  padding-top: 0.5rem;
  padding-bottom: 0rem;
  margin: 0rem;
  cursor: pointer;
}
.tool-page2-tool-wrapper:hover {
  background-color: var(--color-button-background-hover);
  color: var(--color-button-foreground-hover);
}

.tool-page2-tool-premium-wrapper {
  border: 2px solid var(--color-primary);
}

.tool-page2-tool-category {
  font-size: calc(var(--font-size-sm) * 1.8);
  font-family: var(--font-family-accent);
  margin-top: 0.1rem;
  margin-bottom: 0.1rem;
}

.tool-page2-tool-abbr {
  font-size: calc(var(--font-size-sm) * 1.2);
  font-weight: bold;
}

.tool-page2-tool-name {
  font-size: calc(var(--font-size-sm) * 1.4);
  margin-top: 0.1rem;
  margin-bottom: 0.1rem;
}

.tool-page2-tool-en {
  font-size: calc(var(--font-size-sm) * 0.9);
  color: var(--color-gray);
}

.tool-page2-tool-phrase {
  font-size: calc(var(--font-size-sm) * 1.6);
  font-family: var(--font-family-accent);
  margin-top: 1rem;
  margin-bottom: 0.5rem;
  text-align: left;
}

.tool-page2-tool-desc {
  font-size: calc(var(--font-size-sm) * 0.8);
  margin-top: 1rem;
  margin-bottom: 1rem;
  text-align: left;
  line-height: var(--text-line-height-sm);
}

.tool-page2-tool-desc-small {
  font-size: calc(var(--font-size-sm) * 0.9);
  color: var(--color-gray);
}

@media (max-width: 768px) {
  .tool-page2-tool-category {
    font-size: calc(var(--font-size-md) * 1.8);
  }
  .tool-page2-tool-abbr {
    font-size: calc(var(--font-size-md) * 1.2);
  }
  .tool-page2-tool-name {
    font-size: calc(var(--font-size-md) * 1.4);
  }
  .tool-page2-tool-en {
    font-size: calc(var(--font-size-md) * 0.9);
  }
  .tool-page2-tool-phrase {
    font-size: calc(var(--font-size-md) * 1.4);
  }
  .tool-page2-tool-desc {
    font-size: calc(var(--font-size-md) * 0.8);
    line-height: var(--text-line-height-md);
  }
  .tool-page2-tool-desc-small {
    font-size: calc(var(--font-size-md) * 0.7);
    line-height: var(--text-line-height-md);
  }
}